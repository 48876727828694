@charset "UTF-8";

footer {
  flex: none;
  padding-top: 4em;
  width: 100%;
  min-width: 100%;
  z-index: $z-index-footer;
}

.footer-wrap {
  background: rgba($base-color, 0.125);
  line-height: 2;
  padding-top: 2em;
  position: relative;
  & ul {
    justify-content: center;
    @include media(md) {
      justify-content: flex-end;
    }
  }
}
.footer-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-company {
  & li {
    font-size: 1em;
    @include media(lg) {
      font-size: .875em;
    }
    .name {
      font-size: 1.6em;
      font-weight: bold;
      padding-right: 0.25em;
    }
    .name-title{
      display: block;
      font-weight: bold;
      line-height: 1;
    }
    .division{
      display: inline-block;
      font-weight: bold;
      font-size: 1.25em;
    }
    .tel{
      a{
        color: $body-color;
      }
    }
    .tel i,.fax i{
      font-size: 1.25em;
    }
    & + li{
      margin-top: 1em;
    }
  }
  
}
.footer-button {
  li + li{
    margin-top: 0.5em;
  }
}
.footer-middle {
  padding: 1em 0;

  & ul {
    justify-content: center;
    & a {
      color: $gray-light;
    }
    @include media(lg) {
      justify-content: start;
    }
  }
}
.footer-lower {
  background: rgba($base-color, 0.125);
  padding-bottom: 6rem;
  @include media(md) {
      justify-content: start;
      padding-bottom: 0;
    }
}
// コピーライト
.copyright {
  color: $gray-light;
  display: block;
  font-size: 72.5%;
  text-align: center;
  padding: 2em 0 2em;
  width: 100%;
}
