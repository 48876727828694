@charset "UTF-8";

// ---------- ---------- ---------- ---------- ---------- ---------- 全体の大枠
.general-wrapper {
  position: relative;
  width: 100%;
}

.general-layout {
  display: flex;
  flex-flow: column nowrap;
  align-content: space-between;
  min-height: 100vh;
  & header, footer {
    flex: none;
  }
  & main {
    width: 100%;
    flex: 1 0 auto;
  }
}

// ---------- ---------- ---------- ---------- ---------- ---------- wrapper
.wrapper {
  width: 100%;
  min-width: 320px;
  @include media(md) {
    min-width: width(md);
  }
  @include media(lg) {
    min-width: width(lg);
  }
}

// ---------- ---------- ---------- ---------- ---------- ---------- .container
%container {
  padding: 0 container-padding(sm);
  margin: 0 auto;
  width: 100%;
  @include media(md) {
    padding: 0 container-padding(md);
    min-width: width(md);
  }
  @include media(lg) {
    padding: 0 container-padding(lg);
    width: width(lg);
    min-width: width(lg);
  }
}

.container {
  @extend %container;
}

// ---------- ---------- ---------- ---------- ---------- ---------- .row-container
.row-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  @extend %container;

  & .main-column {
    order: 0;
    width: 100%;
    @include media(md) {
      flex: 1 0 1%;
    }
    @include media(lg) {
      order: 1;
    }
  }

  & .right-column {
    margin-top: $main-top-margin-sm;
    order: 2;
    width: 100%;
    @include media(md) {
      margin-top: $main-top-margin-md;
    }
    @include media(lg) {
      margin-top: 0;
      margin-left: $main-side-margin;
      width: $width-right-column;
    }
  }

  & .left-column {
    margin-top: $main-top-margin-sm;
    order: 1;
    width: 100%;
    @include media(md) {
      margin-top: $main-top-margin-md;
    }
    @include media(lg) {
      margin-top: 0;
      order: 0;
      margin-right: $main-side-margin;
      width: $width-left-column;
    }
  }
}

// ---------- ---------- ---------- ---------- ---------- ---------- section
section {
  & + section {
    margin-top: $section-margin;
  }
}

.grecaptcha-badge{
  bottom: 20rem !important;
  z-index: 2;
  @include media(md) {
    bottom: 20rem !important;
  }
}