@charset "UTF-8";

.ullarge {
  font-size: 200%;
  //font-weight:normal;
  line-height: inherit;
}

.exlarge {
  font-size: 150%;
  //font-weight:normal;
  line-height: inherit;
}

.xlarge {
  font-size: 125%;
  //font-weight:normal;
  line-height: inherit;
}

.large {
  font-size: 112.5%;
  //font-weight:normal;
  line-height: inherit;
}

.medium {
  font-size: 87.5%;
  //font-weight: normal;
  line-height: inherit;
}

.small {
  font-size: 75%;
  //font-weight:normal;
  line-height: inherit;
  &.small-block {
    display: inline;
    @include media(lg) {
      display: block;
      margin-top: 1em;
    }
  }
}

.xsmall {
  font-size: 62.5%;
  //font-weight: normal;
  line-height: inherit;
}

