@charset "UTF-8";

// slider
.gallery {
  width: 100%;
}

.gallery-cell {
  width: 100%;
  & img {
    width: 100%;
  }
}

.flickity-page-dots {
  width: 100%;
  padding: 0;
  margin: 10px auto;
  list-style: none;
  text-align: center;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid #ccc;
  border-radius: 50%;
  margin: 4px 12px;
  display: inline-block;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  @include media(lg) {
    margin: 4px 8px;
    }
}

.flickity-page-dots .dot.is-selected {
  border: 2px solid $base-color;
  background-color: $base-color;
}

//
// ピックアップコンテンツ
.pickup-contents {
  overflow: hidden;
  margin: -0.5em;
  padding: 1em;
  @include media(lg) {
    display: flex;
    flex-flow: row wrap;
    padding: 0;
    //justify-content: space-between;
  }
  & .pickup-inner {
    //margin: 0 0 1em;
    padding: 0.5em;
    @include media(lg) {
      flex: 0 0 33.33333%;
      margin: 0;
      max-width: 33.33333%;
    }
    & .module {
      overflow: hidden;
      position: relative;
      width: 100%;
      & img {
        transition: transform .3s linear;
        width: 100%;
      }
      & .pickup-label {
        position: absolute;
        top: 1rem;
        left: 1rem;
        &.beginner{
          padding-left: 1.8rem;
          &::before{
            content: '';
            background-image: url('/images/beginner.png');
            background-size: contain;
            background-repeat: no-repeat;
            display: inline-block;
            height: 1.5rem;
            width: 0.94rem;
            position: absolute;
            left: 0.6rem;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
      & .pickup-title {
        color: #fff;
        background: rgba(0,0,0,.5);
        line-height: 1.4;
        padding: .6em 1em;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        & h3 {
          font-size: 1.6rem;
          margin: 0;
          text-align: center;
        }
        & .date {
          color: #ccc;
          font-size: 1.2rem;
        }
      }
      & a:hover {
        & img {
          transform: scale(1.2);
        }
      }
    }
  }
}
.top-label {
  background: #66d2df;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  font-size: 1.2rem;
  line-height: 1;
  padding: 6px;
  &.base {
    background: $base-color;
  }
  &.first {
    background: $first-color;
  }
  &.second {
    background: $second-color;
  }
  &.third {
    background: $third-color;
  }
}

//
// お知らせ
.news {
  & li {
    border-bottom: dotted 1px #ccc;
    position: relative;
    &.nolink {
      padding: 1em;
    }

    & a:not(.icon) {
      color: inherit;
      display: block;
      padding: 1em 3em 1em 1em;
      transition: background .3s ease-out;
      &:hover {
        background: rgba($base-color, 0.125);
        text-decoration: none;
        &::after {
          transform: translateY(-50%) translateX(0.6em) rotate(45deg);
        }
      }
      &::after {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        border-right: 3px solid $base-color;
        border-top: 3px solid $base-color;
        transform: translateY(-50%) rotate(45deg);
        position: absolute;
        top: 50%;
        right: 2rem;
        transition: transform .3s ease-out;
      }
    }
    & dl {
      & dt {
        font-size: 1em;
        font-weight: bold;
        margin-bottom: 0.5em;
        & span {
          margin-left: 0.35em;
        }
      }
      & dd {
        line-height: 1.6;
      }
    }
  }
}

// バナースライダー
.banner-slider {
  width: 76%;
  margin: 50px auto 30px;
  @include media(lg) {
    width: 960px;
  }

  & div {
    & img {
      width: 100%;
      @include media(lg) {
        width: 92%;
      }
    }
  }
}
.multiple-item {
  & div {
    margin: 0;
    @include media(lg) {
      margin: 0 0 0 6px;
    }
  }
}

img{
  &.pc{
    display: none !important;
    @include media(lg) {
      display: block !important;
    }
  }
  &.sp{
    display: block !important;
    @include media(lg) {
      display: none !important;
    }
  }
}
