@charset "UTF-8";

div,dl,dt,dd,ul,ol,li,
h1,h2,h3,h4,h5,h6,
pre,form,p,blockquote,th,td {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

html {
  font-size: calc(1em * 0.625); // 16px -> 10px
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}

body {
  background: url('../img/bg.jpg') fixed;
  color: $body-color;
  font-family: $font-family;
  font-size: font-size(sm);
  line-height: base-line-height(sm);
  letter-spacing: 0.0425em;
  height: 100%;
  text-rendering: optimizeLegibility;
  font-feature-settings : "pkna" 1;
  -webkit-text-size-adjust: 100%;
  word-wrap : break-word;
  overflow-wrap : break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include media(md) {
    font-size: font-size(md);
    line-height: base-line-height(md);
    min-width: width(md);
  }

  @include media(lg) {
    font-size: font-size(lg);
    line-height: base-line-height(lg);
    min-width: width(lg);
  }
}

a {
  color: $link-color;
  text-decoration: none;
  &:hover {
    color: $link-hover-color;
    text-decoration: none;
  }
}

img {
  // width: 100%;
  height: auto;
  max-width: 100%;
  -ms-interpolation-mode: bicubic;
  display: inline-block;
  vertical-align: middle;
}

hr {
  max-width: width(lg);
  height: 0;
  border-right: 0;
  border-top: 0;
  border-bottom: 1px solid $gray-lighter;
  border-left: 0;
  margin: 1.2rem auto;
  clear: both;

  @include media(lg) {
    margin: .75em auto;
  }
}
