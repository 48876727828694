@charset "UTF-8";

.list-group {
  & li {
    display: block;
    padding: 0em 1em;
    position: relative;

    &.title {
      font-weight: bold;
      padding: .25em 0em;
    }
  }

  &.border-box {
    border: 1px solid $gray-light;
    & li {
      padding-top: 1em;
      padding-bottom: 1em;

      &:not(:first-child) {
        border-top: 1px solid $gray-light;
      }

      &.title {
        background: $base-color;
        color: #fff;
        padding: .25em 1em;
      }
    }
  }
}

.list-group-link {
  & li {
    display: block;
    position: relative;

    &.title {
      font-weight: bold;
      padding: .25em 0em;
    }

    & a {
      display: block;
      padding-left: 1em;
      transition: background-color 0.25s ease-out, color 0.25s ease-out;

      &:hover {
        background-color: $gray-lightest;
        text-decoration: none;
      }
    }
  }

  &.border-box {
    border: 1px solid $gray-light;
    & li {

      &:not(:first-child) {
        border-top: 1px solid $gray-light;
      }

      &.title {
        background: $base-color;
        color: #fff;
        padding: .25em 1em;
      }

      & a {
        padding-top: 1em;
        padding-bottom: 1em;
      }
    }
  }
}
