@charset "UTF-8";

// ナビゲーションの wrap
.global-navigation {
  //position: relative;
  flex: 1 0 auto;
}


// ナビゲーションとバーガーメニューの可視
nav.global,
.g-navi {
  display: none;
  @include media(lg) {
    display: block;
  }
}
.nav-button,
.slicknav_menu {
  display: block;
  z-index: 100;
  @include media(lg) {
    display: none;
  }
}
nav.global {
  padding-top: 2.5em;
}

// PC用ナビゲーション
$easeOutCubic: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1.000);

.g-navi {
  background: transparent;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;

  & > li {
    //flex: 1 0 0;
    text-align: center;
    //position: relative;
    line-height: 1;
    & a {
      background: transparent;
      color: $gray-dark;
      display: block;
      font-size: 1.5rem;
      padding: 1em 0;
      transition: color .25s;
      letter-spacing: 0;
      & i {
        font-size: 1.6rem;
      }
      &:after {
        content: '';
        border-bottom: 3px solid $base-color;
        display: inline-block;
        width: 100%;
        opacity: 0;
        transform: translateY(10px);
        transition: opacity 0.3s, transform 0.3s;
      }
      &:hover {
        color: $base-color;
        text-decoration: none;
        &:after {
          opacity: 1;
          transform: translateY(0px);
        }
      }
    }
    & .child {
      background: url('../img/bg.jpg') fixed;
      display: none;
      position: absolute;
      top: $height-header-lg;
      left: 0;
      width: width-num(lg)-2+rem;
      z-index: 99;
      & li {
        & a {
          color: $gray-dark;
          display: block;
          font-size: 1.4rem;
          padding: 1em;
          &:hover {
            color: $base-color;
            text-decoration: none;
          }
        }
      }
    }
  }
}

.sub-menu-inner {
  display: none;
  @include media(md) {
    display: flex;
    text-align: left;
    & figure {
      margin-right: 1em;
    }
    & img {
      width: 100px;
      min-width: 100px;
    }
    & dl {
      & dt {
        font-size: 1.6rem;
        margin-bottom: .8rem;
      }
    }
  }
}
.sm-menu-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  & img {
    display: inline-block;
    width: 1em;
    margin-right: .2em;
  }
  @include media(md) {
    display: none;
  }
}

.nav-icon{
  margin-right: 0.5em;
  @include media(md) {
    color: $base-color;
    font-size: 1.6rem;
  }
}

// slicknav スマホ用の読み込み
@import "vendor/slicknav";
