@charset "UTF-8";

ul, ol {
  list-style: none;
  line-height: inherit;
  margin: 0;
  padding: 0;

  &.li-mb {
    & > li:not(:last-child) {
      margin-bottom: .8em;
    }
  }
}

// UL
//
ul.disc {
  list-style: disc outside;
  padding-left: 1.5em;
  & ul {
    list-style: circle outside;
    padding-left: 1.5em;
  }
}

ul.square {
  & > li {
    line-height: inherit;
    padding-left: 1.2em;
    position: relative;
    &:before {
      content: '■';
      color: $base-color;
      margin-right: 0.4em;
      position: absolute;
      left: 0;
    }
  }
}

ul.circle {
  & > li {
    line-height: inherit;
    padding-left: 1.2em;
    position: relative;
    &:before {
      content: '●';
      color: $base-color;
      margin-right: 0.4em;
      position: absolute;
      left: 0;
    }
  }
}

ul.kome {
  & > li {
    line-height: inherit;
    padding-left: 1.2em;
    position: relative;
    &:before {
      content: '※';
      margin-right: 0.4em;
      position: absolute;
      left: 0;
    }
  }
}

.list-horizon {
  display: flex;
  flex-flow: row wrap;
  & li:not(:last-child) {
    padding-right: 1em;
  }
}


// OL
//

ol.num {
  list-style: decimal outside;
  padding-left: 1.25em;
  & ol {
    list-style: decimal outside;
    padding-left: 1.25em;
  }
}

// DL
//

dl {
  & dt {
    font-weight: bold;
    &:not(:first-child) {
      margin-top: 1em; }
  }
  & dd {
    line-height: 1.4;
  }
}


//横並び定義リスト
dl.dl-horizon {
  & dt, dd {
    line-height: 1.5;
  }
  & dt {
    float: none;
    &:not(:first-child) {
      margin-top: 0.5em;
      @include media(lg) {
        margin-top: 0.25em;
      }
    }
    @include media(lg) {
      float: left;
      clear: left;
      margin-top: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  & dd {
    &:not(:first-child) {
      margin-top: 0.25em;
    }
  }
  &::after {
    content: "";
    display: block;
    clear: both;
  }
  @for $i from 1 through 40 {
    &.dt#{$i}{
      & dt{
        @include media(lg) {
          width: #{$i}em;
        }
      }
      & dd {
        @include media(lg) {
          margin-left: #{$i}em;
        }
      }
    }
  }
  &.li-mb{
    dt {
      &:not(:first-child) {
        margin-top: 1em !important;
        @include media(lg) {
          margin-top: 1em !important;
        }
      }
      @include media(lg) {
        margin-top: 0 !important;
      }
    }
    dd {
      &:not(:first-child) {
        margin-top: 0.5em !important;
        @include media(lg) {
          margin-top: 1em !important;
        }
      }
    }
  }
}

dl.dt-lg-right dt{
  @include media(lg) {
    text-align: right;
  }
}

ul.notes {
  li {
    line-height: inherit;
    padding-left: 1.5em;
    position: relative;
    &:before {
      font-family: jp;
      content: '※';
      position: absolute;
      left: 0;
    }
  }
}