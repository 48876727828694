@charset "UTF-8";

.tabs-nav {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  justify-content: space-between;
  flex-direction: column;
  @include media(md) {
    flex-direction: row;
  }
  & > li {
    color: #07D;
    position: relative;
    transition: .3s ease-out;
    width: 100%;
    & + li{
      margin-top: .5em;
      @include media(md) {
        margin-top: 0;
        margin-left: .5em;
      }
    }
    & a {
      background: lighten($base-color, 40%);
      border-radius: 6px;
      font-weight: bold;
      display: flex;;
      padding: .8rem 1.2rem;
      transition: background-color 0.25s ease-out;
      color: $base-color;
      text-align: center;
      line-height: 1.4;
      height: 100%;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      @include media(md) {
        border-radius: 6px 6px 0 0;
        &:hover {
          cursor: pointer;
          text-decoration: none;
        }
      }
      .split{
        display: flex;
        & + .split::before{
          content: "・";
          display: block;
        }
        @include media(md) {
          display: block;
          width: 100%;
          & + .split::before{
            display: none;
          }
        }
      }
    }
    &.active a {
      //border-bottom: 4px solid $base-color;
      background: $base-color;
      color: #fff;
    }
  }
}
.tabs-nav > li a:not(.active):hover,
.tabs-nav > li a:not(.active):focus {
  // background: lighten($base-color, 20%);
  // color: #fff;
  // outline: none;
}

.tabs-content {
  border: 1px solid #ddd;
  padding: 1.5em;
  background: #fff;
  margin-top: 1em;
  @include media(md) {
    margin-top: 0;
  }
}
