@charset "UTF-8";

header {
  width: 100%;
  //position: fixed;
  top: 0;
  z-index: $z-index-header;
}

.header-wrap {
  background: $header-color;
  position: relative;
}

.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $height-header-sm;
  @include media(md) {
    height: $height-header-md;
  }
  @include media(lg) {
    height: $height-header-lg;
    position: relative;
  }

  & .header-logo {
    font-size: 1.5714285em;
    line-height: 1.4;
    margin-right: 1em !important;
    @include media(lg) {
      margin-right: 1.5em;
    }
    & a {
      color: #333;
      transition: opacity 0.2s;
      & .text-sub {
        //color: #595959;
        font-size: .5454545em;
        font-weight: bold;
      }
      & .text-name {
        //color: #595959;
        font-weight: bold;
        transition: color .25s;
        //color: $base-color;
        span{
          font-size: 0.75em;
          padding-left: 0.25em;
        }
      }
      &:hover {
        //color: lighten(#333,20%);
        opacity: 0.6;
      }
    }
    .company-name{
      font-size: 0.625em;
      font-weight: bold;
      letter-spacing: 0;
    }
    .logo{
      width: 24rem;
      display: inline-block;
      @include media(md) {
        width: 30rem;
      }
    }
  }
}
