@charset "UTF-8";

p {
  font-size: inherit;
  line-height: inherit;
  text-rendering: optimizeLegibility;
}

i {
  font-style: italic;
  line-height: inherit;
}

strong,
em,
b {
  font-style: normal;
  font-weight: bold;
  line-height: inherit;
}

span.icon {
  & img {
    vertical-align: middle;
    margin: 0 0.2em;
    width: 1em;
    height: 1em;
  }
}

// heading
//
//

h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
  text-rendering: optimizeLegibility;
  margin-top: 0;
  margin-bottom: 0.4em;
  line-height: 1.4;
}

// ---------- ---------- ---------- ---------- ---------- h1
h1 {
  font-size: $h1-font-size;
  @include media(md) {
    font-size: $h1-font-size * $h-scale-medium;
  }
  @include media(lg) {
    font-size: $h1-font-size * $h-scale-large;
  }
  &.first {
    color: $base-color;
    font-size: 1.85714285em;
    text-align: center;
    margin: .5em 0;
    & span {
      color: #999;
      display: block;
      font-size: .425em;
    }
  }
}

// ---------- ---------- ---------- ---------- ---------- h2
h2 {
  font-size: $h2-font-size;
  @include media(md) {
    font-size: $h2-font-size * $h-scale-medium;
  }
  @include media(lg) {
    font-size: $h2-font-size * $h-scale-large;
  }
  &.first {
    //background: #fff;
    border-left: solid 2px $base-color;
    color: #333;
    font-size: 1.8rem;
    padding: .75em 1em;
  }
  &.second {
    font-size: 1.25em;
    padding: 0.8em 0;
    text-align: center;
    margin-bottom: 1.5em;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
}

// ---------- ---------- ---------- ---------- ---------- h3
h3 {
  font-size: $h3-font-size;
  @include media(md) {
    font-size: $h3-font-size * $h-scale-medium;
  }
  @include media(lg) {
    font-size: $h3-font-size * $h-scale-large;
  }
  &.first {
    font-size: 1.125em;
    color: $base-color;
    font-weight: bold;
    padding: .4em .875em;
    margin-top: 1.5em;
    margin-bottom: 1em;
    //background: #fff;
    border-left: 4px solid $base-color;
    overflow:hidden;
  }
  &.second {
    color: #555;
    display: inline-block;
    font-size: 1em;
    position: relative;
    margin: 2em 0;
    &::before {
      content: "";
      position: absolute;
      bottom: -1rem;
      width: 6rem;
      border-bottom: 3px solid $base-color;
    }
  }
}
p + h3.first {
  //margin-top: 3.2em;
}
p + h3.second,
ul + h3.second,
p + h4.first,
ul + h4.first {
  //margin-top: 2em;
}

// ---------- ---------- ---------- ---------- ---------- h4
h4 {
  font-size: $h4-font-size;
  @include media(md) {
    font-size: $h4-font-size * $h-scale-medium;
  }
  @include media(lg) {
    font-size: $h4-font-size * $h-scale-large;
  }
  &.first {
    border-bottom: 1px dotted $base-color;
    color: $second-color;
    font-size: 1em;
    margin-top: 1.5em;
    margin-bottom: 1em;
    padding-bottom: .4em;
  }
}

// ---------- ---------- ---------- ---------- ---------- h5
h5 {
  font-size: $h5-font-size;
  @include media(md) {
    font-size: $h5-font-size * $h-scale-medium;
  }
  @include media(lg) {
    font-size: $h5-font-size * $h-scale-large;
  }
}

// ---------- ---------- ---------- ---------- ---------- h6
h6 {
  font-size: $h6-font-size;
  @include media(md) {
    font-size: $h6-font-size * $h-scale-medium;
  }
  @include media(lg) {
    font-size: $h6-font-size * $h-scale-large;
  }
  &.second {
    border-bottom: 1px solid #CFD8DC;
    border-left: 1px solid #CFD8DC;
    color: #424242;
    margin-bottom: 0.6em;
    padding-bottom: 0.1em;
    padding-left: 0.4em;
  }
}

//
// figcaption
//
figcaption {
  margin-top: 0.5em;
  font-size: 12px;
  @include media(lg) {
    font-size: 14px;
  }
}

// code
//
//

pre {
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

code,
pre {
  background-color: #f8f8f8;
  //border: 1px solid #e0e0e0;
  //border-radius: 3px;
  color: #484848;
  font-family: Consolas, monospace;
  font-size: .9375em;
  margin: 1em 0;
}

pre {
  padding: 0.5em 1.5em;
}

code {
  padding: 1em;
  margin: 0 0.4em;
  vertical-align: middle;
}

pre code {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  vertical-align: inherit;
}

a{
  color: darken($base-color, 5%);
}