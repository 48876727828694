@charset "UTF-8";

.form-field {
  margin-bottom: 2em;
  @include media(lg) {
    margin-bottom: 3em;
  }
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: .42857em;
  font-weight: bold;
  &.error {
    color: $color-red;
    font-size: .875em;
    font-weight: normal;
    padding-left: 0.5em;
  }
}

// md,lg 時に横組みにする
form.form-horizon {
  & .form-field {
    display: block;
    @include media(md) {
      display: flex;
    }
    & > label.entry {
      @include media(md) {
        flex: 0 0 120px;
      }
      @include media(lg) {
        flex: 0 0 140px;
      }
    }
  }
}

// width 100% の挙動制御
.form-item {
  flex: 1;
}

input,
textarea {
  display: block;
  width: 100%;
  //height: 3em;
  padding: 6px 12px;
  font-size: 1.6rem;
  line-height: 1.42857143;
  color: #333;
  background: #fff;
  //background-image: none;
  border: 1px solid #ccc;
  box-shadow: none;
  transition: border-color ease-in-out .15s, background-color ease-in-out .15s;

  &:focus {
    background: inherit;
    border-color: $base-color;
    box-shadow: 0 0 6px 1px rgba($base-color, .075);
    outline: 0;
    &::placeholder {
      color: transparent;
    }
  }
  &::-moz-placeholder {
    color: #999;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: #999;
  }
  &::-webkit-input-placeholder {
    color: #999;
  }
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }
}

// validate エラー時の border
input,
.select select,
textarea {
  &.error {
    border: 1px solid $color-red;
  }
}

input {
  height: 3em;
}
textarea {
  height: auto;
}

input[type="file"]{
  height: auto;
  padding: 6px;
}


 // ------------------------------------------------------- ドロップダウンリスト
select {
  width: 100%;
  &::-moz-focus-inner {
    border: 0;
  }
}

.select {
  display: inline-block;
  position: relative;
  & select {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #333;
    height: 3em;
    font-size: 1.6rem;
    line-height: 1.42857143;
    padding: 6px 32px 6px 12px;
    position: relative;
    cursor: pointer;
    display: block;
    outline: none;
    overflow: hidden;
  }

  &:after { // 矢印
    border: 1px solid #999;
    border-right: 0;
    border-top: 0;
    content: '';
    display: block;
    height: 7px;
    pointer-events: none;
    position: absolute;
    transform: rotate(-45deg);
    width: 7px;
    margin-top: -5px;
    right: 16px;
    top: 50%;
  }
}

select[multiple],
select[size] {
  height: auto;
}

// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ チェックボックス、ラジオボタン

.checkbox-group, .radio-group {
  margin-bottom: 3.6rem;

  & label {
    display: inline-block;
    font-weight: normal;
  }
}

.label-checkbox input[type="checkbox"],
.label-radio input[type="radio"] {
  display: none;
}

// ++++++++++ ++++++++++ ++++++++++ checkbox
.label-checkbox {
  cursor: pointer;
  position: relative;


  & .lever {
    //background: lighten($gray-lightest, 5%);
    color: $gray;
    display: inline-block;
    padding: 0.5em 1em 0.5em 3em;
    margin-right: 1em;
    -webkit-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out;

    &:before {
      content:'';
      position: absolute;
      top: 50%;
      left: 1em;
      margin-top: -0.65em;
      border: 1px solid $gray-lighter;
      background: #fff;
      width: 1.5em;
      height: 1.5em;
      //border-radius:100%;
      transition: all 0.15s ease-out;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 1.5em;
      margin-top: -0.5em;
      width: 0.5em;
      height: 1em;
      border-right: 2px solid #fff;
      border-bottom: 2px solid #fff;
      transform: rotate(45deg);
      transition: opacity 0.15s linear;
      opacity: 0;
    }
  }
}

//.label-checkbox:hover .lever:before,
.label-checkbox input[type="checkbox"]:checked + .lever:before {
  background: $base-color;
}

.label-checkbox input[type="checkbox"]:checked + .lever:after {
  opacity: 1;
}

// ++++++++++ ++++++++++ ++++++++++ radio button
.label-radio {
  cursor: pointer;
  color: $gray;
  font-weight: normal;
  letter-spacing: 0;
  display: inline-block;
  position: relative;
  margin-right: 1em;

  & .lever {
    padding-left: 1.8em;
    &:before {
      content: '';
      border: 1px solid $gray-light;
      width: 1.5em;
      height: 1.5em;

      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &:after {
      content:'';
      opacity: 0;
      position: absolute;
      left: 0.5em;
      top: 50%;
      width: 0.5em;
      height: 0.5em;
      background: #fff;
      border-radius: 50%;
      transform: translateY(-50%);
      transition: all 0.15s linear;
    }
  }
}

.label-radio input[type="radio"]:checked + .lever:before {
  background: $base-color;
}

.label-radio input[type="radio"]:checked + .lever:after {
  opacity: 1;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
     -moz-appearance:textfield;
}

.upload-error .error{
 font-size: 1em;
 padding-left: 0;
 margin-bottom: 0;
}