@charset "UTF-8";

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  padding: 0;
  border: 0;
  line-height: 1;
  &:focus {
    outline: 1px dotted;
    outline: 0px auto -webkit-focus-ring-color;
  }
}

.button {
  background-color: $base-color;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  text-align: center;
  line-height: 1.5 !important;
  -webkit-appearance: none;
  transition: background-color 0.25s ease-out;
  vertical-align: middle;
  padding: 1em;
  //margin: 0.4em 0;
  @include media(md) {
    padding: 0.8em;
  }

  &:hover {
    background-color: darken($base-color,15%);
    color: #fff;
    text-decoration: none; }
  & a:visited .button {
    color: inherit; }
  // Expanded
  &.expanded {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0; }
  // サイズ
  //&.xsmall {
  //  font-size: 1rem;
  //  padding: 0.7em .8em; }
  //&.small {
  //  font-size: 1.2rem;
  //  padding: 0.7em 0.8em ; }
  //&.large {
  //  font-size: 1.8rem;
  //  padding: 0.6em 1em;
  //}
  // カラー
  &.first {
    background-color: $first-color;
    &:hover {
    background-color: darken($first-color,15%); }
  }
  &.second {
    background-color: $second-color;
    &:hover {
    background-color: darken($second-color,15%); }
  }
  &.third {
    background-color: $third-color;
    &:hover {
    background-color: darken($third-color,15%); }
  }
  &.fourth {
    background-color: $fourth-color;
    &:hover {
    background-color: darken($fourth-color,15%); }
  }
  &.five {
    background-color: $five-color;
    &:hover {
    background-color: darken($five-color,15%); }
  }
  // アウトライン
  &.outline {
    background-color: #fff;
    border: 1px solid $base-color;
    color: $base-color;
    text-shadow: none;

    &:hover {
      background-color: lighten($base-color,40%); }
      // アウトライン カラー
      &.first {
        background-color: #fff;
        border: 1px solid $first-color;
        color: $first-color;
        &:hover {
          background-color: lighten($first-color,35%); }
        }
      &.second {
        background-color: #fff;
        border: 1px solid $second-color;
        color: $second-color;
        &:hover {
          background-color: lighten($second-color,35%); }
        }
      &.third {
        background-color: #fff;
        border: 1px solid $third-color;
        color: $third-color;
        &:hover {
          background-color: lighten($third-color,35%); }
        }
      &.fourth {
        background-color: #fff;
        border: 1px solid $fourth-color;
        color: $fourth-color;
        &:hover {
          background-color: lighten($fourth-color,35%); }
        }
      &.five {
        background-color: #fff;
        border: 1px solid $five-color;
        color: $five-color;
        &:hover {
          background-color: lighten($five-color,35%); }
        }
      &.ghost {
        background-color: transparent;
        border: 1px solid rgba(#fff,0.7);
        color: rgba(#fff,0.7);
        &:hover {
          background-color: rgba(#000, 0.3); }
      }
      &.footer-button {
        background-color: #fff;
        border-radius: 4px;
        border: 1px solid $gray-dark;
        color: $gray-dark;
        &:hover {
          background-color: $base-color;
          border: 1px solid $base-color;
          color: #fff;
        }
      }
  }
}
//
// footer google map
.google-map {
  background-color: $base-color;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: block;
  //font-size: 0.875em;
  text-align: center;
  line-height: 1 !important;
  -webkit-appearance: none;
  transition: background-color 0.25s ease-out;
  margin: 0.5em 0 0;
  padding: .875em;
  & span:before {
    font-family: FontAwesome;
    content: '\f0a9';
    color: inherit;
    display: inline-block;
    margin-right: .4em;
  }
  @include media(lg) {
    display: inline-block;
    margin: 0 0 0 .5em;
    padding: .4em .8em;
  }
  &:hover {
    background-color: lighten($base-color, 20%);
    color: #fff;
  }
}

form button.button-block {
  display: block;
  width: 100%;
  @include media(lg) {
    margin: 2rem auto;
    width: 25%;
  }
}
