@charset "UTF-8";

// 段落の余白
p {
  & + p {
    margin-top: paragraph-margin(sm);
    @include media(md) {
      margin-top: paragraph-margin(md);
    }
    @include media(lg) {
      margin-top: paragraph-margin(lg);
    }
  }
  & + .grid, & + .grid-flex, & + .grid-tile {
    margin-top: paragraph-margin(sm);
    @include media(md) {
      margin-top: paragraph-margin(md);
    }
    @include media(lg) {
      margin-top: paragraph-margin(lg);
    }
  }
}

.element {
  margin-top: paragraph-margin(sm);
  @include media(md) {
    margin-top: paragraph-margin(md);
  }
  @include media(lg) {
    margin-top: paragraph-margin(lg);
  }
}

.fw-bold{
  font-weight: bold;
}

.border{
  border: solid 1px #ccc;
}

//動画.map埋め込み
.iframe-wrapper {
  position: relative;
  width: 100%;
  &:before {
    content:"";
    display: block;
    padding-top: 56.25%; /* 高さと幅の比を16:9に固定。9/16*100=56.25 */
  }
  .iframe-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}