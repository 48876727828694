@charset "UTF-8";


// メニューボタン
.slicknav_btn {
  display: inline-flex;
  align-content: center;
  align-items: center;
  background: #fff;
  border-radius: 56px;
  width: 56px;
  height: 56px;
  padding: 1.2rem;
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 9999;
  &:hover {
    text-decoration: none;
  }
}

.slicknav_menutxt { // ボタン内のテキスト
  display: none;
  color: #fff;
  margin-right: 0.4em;
}

.slicknav_icon { // バーガー用ボーダー
  display: block;
  width: 100%;

  & span {
    width: 100%;
    height: 2px; // 太さ
    background-color: #333;
    display: block;
    transition: all .4s;

    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      margin: 8px 0; // 間隔
    }
    &:nth-of-type(3) {
      bottom: 0;
    }
  }
}
.slicknav_open .slicknav_icon { // バーガー用ボーダー OPEN時
  background: transparent;

  & span {
    &:nth-of-type(1) {
      transform: translateY(10px) rotate(45deg);
    }
    &:nth-of-type(2) {
      opacity: 0;
    }
    &:nth-of-type(3) {
      transform: translateY(-10px) rotate(-45deg);
    }
  }
}

// リスト
.slicknav_nav,
.slicknav_nav ul {
  overflow: hidden;
}
.slicknav_nav ul,
.slicknav_nav li {
  display: block;
}

.slicknav_nav {
  background: rgba(0,0,0,0.8);
  color: #fff;
  font-size: 1.2em;
  padding-top: $height-header-sm;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 9998;

  & ul { // 子メニュー
    & li {
      position: initial;
      & a {
        font-size: 1.6rem;
        padding: 0.4em 0;
      }
      & ul { // 孫メニュー
        & li {
          & a {
          }
        }
      }
    }
  }

  & > li {
    text-align: center;
    &.slicknav_parent a.slicknav_item {
      position: relative;
      &:after { // 子メニューの矢印
        font-family: FontAwesome;
        font-size: 1.6em;
        content: '\f107';
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 1em;
        transform: translateY(-50%);
      }
      & .slicknav_arrow { display: none; } // 元の矢印を消去
    }
  }

  & a {
    display: block;
    color: #fff;
    font-size: 1.8rem;
    padding: 1em;
    transition: color .25s;
    &.slicknav_item a {
      background: transparent;
    }

    &.slicknav_item:hover > a,
    &:not(.slicknav_item):hover {
      color: $base-color;
    }
  }

  & .slicknav_txtnode { // リンクなし
    padding: 1rem;
  }

  & .slicknav_item a {
    padding: 0;
    margin: 0;
  }

  & .slicknav_parent-link a {
    padding: 0;
    margin: 0;
  }
}

