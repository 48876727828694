@charset "UTF-8";


table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	padding: 0;
	margin: 0 0 1em;

  & th, td {
    border: 1px solid $table-border-color;
    padding: 0.5em 1em;
  }

  & th {
    background: $table-th-bg-color;
  }
  & td {
    background: $table-td-bg-color;
  }
}

table.center {
  & th, td {
    text-align: center;
  }
}

// th block
.th-block {
  & th, td {
    display: block;
    @include media(md) {
      display: table-cell;
    }
  }
  & th {
    width: 100%;
    @include media(md) {
      width: 15%;
    }
  }
  & td {
    border-top: none;
    border-bottom: none;

    @include media(md) {
      border-top: 1px solid $table-border-color;
      border-bottom: 1px solid $table-border-color;
    }
  }
  & tr:last-child td {
      border-bottom: 1px solid $table-border-color;
  }
}

// 装飾なし
table.clear {
  margin: 0;
  & th, td {
    background: #fff;
    border: none;
    padding: 0;
  }

  &.confirm { // フォームの確認画面用
    & th, td {
      display: block;
      @include media(md) {
        border-bottom: 24px solid #fff;
        display: table-cell;
      }
    }
    & th {
      background: #ebf3f9;
      color: $base-color;
      font-size: 1.2rem;
      font-weight: normal;
      text-align: left;
      padding: .2em 1em;
      width: 100%;
      @include media(md) {
        font-size: 1.3rem;
        width: 28%;
      }
      @include media(lg) {
        font-size: 1.4rem;
      }
    }
    & td {
      padding: 1em;
      @include media(md) {
        padding: .2em .5em .2em 1em;
      }
    }
  }
}

// スクロールを出すためのwrapper
.table-scroll {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  @include media(md) {
    white-space: normal;
  }
}

.table-fixed{
  table-layout: fixed;
}

td.strong{
  background: lighten($fourth-color, 30%);
  font-weight: bold;
}