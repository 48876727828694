@charset "UTF-8";

.clearfix:after {
  content:" ";
  display:block;
  clear:both;
}

.block-center {
	display: block;
	margin: 0 auto;
}

.ov-hidden {
  overflow: hidden;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-justify {
  text-align: justify;
}

@for $i from 10 through 20 {
  .lh-#{$i} {
    line-height: #{$i * 0.1};
  }
}

@for $i from 1 through 20 {
  .w#{$i * 5} {
    width: #{$i * 5%};
  }
}

.block-elm{
  display: block;
}

.inblock-elm{
  display: inline-block;
}

.fixed-bnr{
  position: fixed;
  z-index: 3;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
  @include media(md) {
    flex-direction: column;
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
    width: auto;
    box-shadow: none;
  }
  .bnr-item{
    background: #ccc;
    color: #fff;
    font-weight: bold;
    //width: 50%;
    width: 100%;
    height: 5rem;
    line-height: 1.4;
    text-align: center;
    transition: background-color 0.2s;
    @include media(md) {
      border-radius: 5px 0 0 5px;
      font-size: 0.9375em;
      //writing-mode: vertical-rl;
      //text-orientation: upright;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
      width: auto;
      height: auto;
      border-top: solid 2px #fff;
      border-bottom: solid 2px #fff;
      border-left: solid 2px #fff;
      min-width: 12rem;
    }
    br{
      display: none;
      @include media(md) {
        display: block;
      }
    }
    & > span{
      position: relative;
      padding: 0.5em 0 0.5em 7em;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      @include media(md) {
        padding: 2em 1em 1.5em 1em;
      }
      &::before{
        content: '';
        background-image: url('/images/contact/callcenter.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 6em;
        height: 6em;
        display: block;
        margin-right: 1em;
        position: absolute;
        left: 0;
        bottom: 0;
        @include media(md) {
          margin-right: 0;
          top: -3.5em;
          left: auto;
          bottom: auto;
        }
      }
    }
    + .bnr-item{
      @include media(md) {
        margin-top: 1em;
      }
    }
    &.first{
      background-color: $first-color;
      &:hover{
        background-color: darken($first-color,5%);
      }
    }
    &.second {
      background-color: $second-color;
      &:hover{
        background-color: darken($second-color,5%);
      }
    }
    &.third {
      background-color: $third-color;
      &:hover{
        background-color: darken($third-color,5%);
      }
    }
  }
}