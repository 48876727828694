@charset "UTF-8";

.fc-base   { color: $base-color; }
.fc-first  { color: $first-color; }
.fc-second { color: $second-color; }
.fc-third  { color: $third-color; }
.fc-fourth { color: $fourth-color; }
.fc-five   { color: $five-color; }

.fc-red    { color: $color-red; }
.fc-orange { color: $color-orange; }
.fc-green  { color: $color-green; }
.fc-yellow { color: $color-yellow; }
.fc-white  { color: $color-white; }
.fc-black  { color: $color-black; }

.fc-gray-dark     { color: $gray-dark; }
.fc-gray          { color: $gray; }
.fc-gray-light    { color: $gray-light; }
.fc-gray-lighter  { color: $gray-lighter; }
.fc-gray-lightest { color: $gray-lightest; }
