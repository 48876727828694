@charset "UTF-8";

// Pagetop

.pagetop {
  position: fixed;
  bottom: 8rem;
  right: 1rem;
  z-index: 2;
  display: none;
  @include media(lg) {
    bottom: 6rem;
    right: 3rem;
  }

  & a {
    display: block;
    background-image: url('/images/musa.svg');
    background-repeat: no-repeat;
    background-size: cover;
    //background-color: rgba($base-color,0.7);
    // border-radius: 4px;
    // text-align: center;
    // color: #fff;
    // line-height: 60px;
    //font-size: 14px;
    //text-decoration: none;
    //transition: background-color 0.25s ease-out, color 0.25s ease-out;
    width: 60px;
    height: 96px;
    transition: 0.25s;
    //padding: 0.4rem;
    @include media(md) {
      width: 72px;
      height: 115px;
    }
    @include media(lg) {
      //width: 60px;
      //height: 60px;
    }
    &:hover {
       //background-color: rgba($base-color,0.9);
       //opacity: 0.5;
    }
    // &:after {
    //   content: '';
    //   display: inline-block;
    //   width: 18px;
    //   height: 18px;
    //   border-right: 3px solid #fff;
    //   border-top: 3px solid #fff;
    //   transform: rotate(-45deg);
    //   margin-top: 0.875em;
    //   transition: transform .3s ease-out;
    //   @include media(md) {
    //     width: 20px;
    //     height: 20px;
    //     margin-top: 1em;
    //   }
    //   @include media(lg) {
    //     width: 24px;
    //     height: 24px;
    //     margin-top: 1.325em;
    //   }
    // }
  }
}
