@charset "UTF-8";

.breadlist-wrapper {
  background: #fff;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin-bottom: 2em;
}

.breadlist {
  display: flex;
  align-items: center;
  background: transparent;
  border-radius: .4rem;
  font-size: 0.875em;
  list-style: none;
  padding: 1em 0;
  // clearfix
  &::after {
    display: table;
    clear: both;
    content: "";
  }
  & li {
    line-height: 1.1;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & + li::before {
      padding-right: .5em;
      padding-left: .5em;
      color: #ccc;
      font-family: FontAwesome;
      content: "\f101";
      font-size: 1.2em;
    }

    & a {
      color: $gray-dark;
      &:hover {
        color: $base-color;
      }
    }

    &.active {
      color: $gray;
    }
  }
}
