@charset "UTF-8";

.label {
  background-color: $base-color;
  border-radius: 0px;
  display: inline-block;
  //margin-right: 1em;
  padding: .5em .6em .6em;
  font-size: 75%;
  line-height: 1 !important;
  color: #fff;
  text-align: center;
  //white-space: nowrap;
  position: relative;

  &.small {
    font-size: 68.75%;
  }

  &.large {
    font-size: 100%;
    padding: 0.075em 0.6em;
  }
  &.first {
    background-color: $first-color; }
  &.second {
    background-color: $second-color; }
  &.third {
    background-color: $third-color; }
  &.fourth {
    background-color: $fourth-color; }
  &.five {
    background-color: $five-color; }
  &.red {
    background-color: $color-red; }

  &.outline {
    background: #fff;
    border: 1px solid $base-color;
    color: $base-color;
    &.first {
      border-color: $first-color;
      color: $first-color; }
    &.second {
      border-color: $second-color;
      color: $second-color; }
    &.third {
      border-color: $third-color;
      color: $third-color; }
    &.fourth {
      border-color: $fourth-color;
      color: $fourth-color; }
    &.five {
      border-color: $five-color;
      color: $five-color; }
    &.red {
      border-color: $color-red;
      color: $color-red;  }
  }

  &.extended {
    display: block;
  }
}
