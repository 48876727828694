@charset "UTF-8";

// 各種アイコンの定義
%pdf {
  &::after { content: $icon-pdf !important; }
  &.left  {
    &::after { content: none !important; }
    &::before { content: $icon-pdf !important; }
  }
}
%nw {
  &::after { content: $icon-window !important; }
  &.left  {
    &::after { content: none !important; }
    &::before { content: $icon-window !important; }
  }
}
%tel {
  &::after { content: $icon-telephone !important; }
  &.left  {
    &::after { content: none !important; }
    &::before { content: $icon-telephone !important; }
  }
}
%mail {
  &::after { content: $icon-mail !important; }
  &.left  {
    &::after { content: none !important; }
    &::before { content: $icon-mail !important; }
  }
}
%fax {
  &::after { content: $icon-fax !important; }
  &.left  {
    &::after { content: none !important; }
    &::before { content: $icon-fax !important; }
  }
}
%privacy {
  &::after { content: $icon-privacy !important; }
  &.left  {
    &::after { content: none !important; }
    &::before { content: $icon-privacy !important; }
  }
}
%link {
  &::after { content: $icon-link !important; }
  &.left  {
    &::after { content: none !important; }
    &::before { content: $icon-link !important; }
  }
}
%company {
  &::after { content: $icon-company !important; }
  &.left  {
    &::after { content: none !important; }
    &::before { content: $icon-company !important; }
  }
}
%policy {
  &::after { content: $icon-policy !important; }
  &.left  {
    &::after { content: none !important; }
    &::before { content: $icon-policy !important; }
  }
}
%access {
  &::after { content: $icon-access !important; }
  &.left  {
    &::after { content: none !important; }
    &::before { content: $icon-access !important; }
  }
}
%recruit {
  &::after { content: $icon-recruit !important; }
  &.left  {
    &::after { content: none !important; }
    &::before { content: $icon-recruit !important; }
  }
}
%circle {
  &::after { content: $icon-circle !important; }
  &.left  {
    &::after { content: none !important; }
    &::before { content: $icon-circle !important; }
  }
}
%close {
  &::after { 
    content: $icon-close !important;
    font-size: 1.25em;
  }
  &.left  {
    &::after { content: none !important; }
    &::before { 
      content: $icon-close !important;
      font-size: 1.25em;
    }
  }
}

// デフォルトの疑似要素を定義
%icon-default {
  font-family: FontAwesome;
  content: $icon-arrows;
  color: inherit;
  display: inline-block;
}

//
//
//
.icon {
  & span {
    &::after {
      @extend %icon-default;
      margin-left: .4em;
    }
    &.left {
      &::after { content: none; }
      &::before {
        @extend %icon-default;
        margin-right: .4em;
      }
    }
    &.pdf {
      @extend %pdf;
    }
    &.nw {
      @extend %nw;
    }
    &.tel {
      @extend %tel;
    }
    &.mail {
      @extend %mail;
    }
    &.fax {
      @extend %fax;
    }
    &.privacy {
      @extend %privacy;
    }
    &.link {
      @extend %link;
    }
    &.company {
      @extend %company;
    }
    &.policy {
      @extend %policy;
    }
    &.access {
      @extend %access;
    }
    &.recruit {
      @extend %recruit;
    }
    &.circle {
      @extend %circle;
    }
    &.close {
      @extend %close;
    }
  } // color
  & span.ic-base {
    &::after, &::before {
      color: $base-color;
    }
  }
  & span.ic-first {
    &::after, &::before {
      color: $first-color;
    }
  }
  & span.ic-second {
    &::after, &::before {
      color: $second-color;
    }
  }
  & span.ic-third {
    &::after, &::before {
      color: $third-color;
    }
  }
  & span.ic-fourth {
    &::after, &::before {
      color: $fourth-color;
    }
  }
  & span.ic-five {
    &::after, &::before {
      color: $five-color;
    }
  }
  & span.ic-red {
    &::after, &::before {
      color: $color-red;
    }
  }
}


// button
//
//
.button, .button.outline {
  & span {
    &::after {
      @extend %icon-default;
      margin-left: .4em;
    }
    &.left {
      &::after { content: none; }
      &::before {
        @extend %icon-default;
        margin-right: .4em;
      }
    }
    &.pdf {
      @extend %pdf;
    }
    &.nw {
      @extend %nw;
    }
    &.tel {
      @extend %tel;
    }
    &.mail {
      @extend %mail;
    }
    &.fax {
      @extend %fax;
    }
    &.privacy {
      @extend %privacy;
    }
    &.link {
      @extend %link;
    }
    &.recruit {
      @extend %recruit;
    }
    &.circle {
      @extend %circle;
    }
    &.close {
      @extend %close;
    }
  } // color
  & span.ic-base {
    &::after, &::before {
      color: $base-color;
    }
  }
  & span.ic-first {
    &::after, &::before {
      color: $first-color;
    }
  }
  & span.ic-second {
    &::after, &::before {
      color: $second-color;
    }
  }
  & span.ic-third {
    &::after, &::before {
      color: $third-color;
    }
  }
  & span.ic-fourth {
    &::after, &::before {
      color: $fourth-color;
    }
  }
  & span.ic-five {
    &::after, &::before {
      color: $five-color;
    }
  }
}
