@charset "UTF-8";

//
// grid-flex
//
.grid-flex {
  display: block;
  margin: (- container-padding-grid(sm)) (- container-padding-grid(sm)) 0;
  @include media(md) {
    display: flex;
    flex-flow: row wrap;
    max-width: width(md);
    margin: (- container-padding-grid(md)) (- container-padding-grid(md)) 0;
  }
  @include media(lg) {
    max-width: width(lg);
    margin: (- container-padding-grid(lg)) (- container-padding-grid(lg)) 0;
  }
  & +.grid, & +.grid-flex, & +.grid-tile {
    margin: 0 (- container-padding-grid(sm)) 0;
    @include media(md) {
      max-width: width(md);
      margin: 0 (- container-padding-grid(md)) 0;
    }
    @include media(lg) {
      max-width: width(lg);
      margin: 0 (- container-padding-grid(lg)) 0;
    }
  }
}
//
// grid-tile
//
.grid-tile {
  display: flex;
  flex-flow: row wrap;
  margin: (- container-padding-grid(sm)) (- container-padding-grid(sm)) 0;
  @include media(md) {
    max-width: width(md);
    margin: (- container-padding-grid(md)) (- container-padding-grid(md)) 0;
  }
  @include media(lg) {
    max-width: width(lg);
    margin: (- container-padding-grid(lg)) (- container-padding-grid(lg)) 0;
  }

  @for $i from 1 through $grid-columns {
    .sm-#{$i} {
      @include make-grid(100%/$i);
    }
  }

  @include media(md) {
    @for $i from 1 through $grid-columns {
      .md-#{$i} {
        @include make-grid(100%/$i);
      }
    }
  }

  @include media(lg) {
    @for $i from 1 through $grid-columns {
      .lg-#{$i} {
        @include make-grid(100%/$i);
      }
    }
  }
  & +.grid, & +.grid-flex, & +.grid-tile {
    margin: 0 (- container-padding-grid(sm)) 0;
    @include media(md) {
      max-width: width(md);
      margin: 0 (- container-padding-grid(md)) 0;
    }
    @include media(lg) {
      max-width: width(lg);
      margin: 0 (- container-padding-grid(lg)) 0;
    }
  }
}

.column {
  padding: container-padding-grid(sm);
  flex: 1 1 0;
  @include media(md) {
    padding: container-padding-grid(md);
    width: auto;
  }
  @include media(lg) {
    padding: container-padding-grid(lg);
  }
  &.narrow {
    flex: 1 1 0;
    @include media(md) {
      flex: none;
    }
    @include media(lg) {
      flex: none;
    }
  }
}


// カラムの揃え方
//
// 横組み
.align-end {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }


// 縦組み  旧Android iOS6以前は未対応
.align-end-vertical {
  align-content: flex-end; }

.align-center-vertical {
  align-content: center; }

.align-justify-vertical {
  align-content: space-between; }

.align-spaced-vertical {
  align-content: space-around; }


// 子要素の天地の揃え align-itemsは親要素・align-selfは子要素
.item-top {
  align-items: flex-start; }
.item-top-child {
  align-self: flex-start; }

.item-end {
  align-items: flex-end; }
.item-end-child {
  align-self: flex-end; }

.item-center {
  align-items: center; }
.item-center-child {
  align-self: center; }

.item-stretch {
  align-items: stretch; }
.item-stretch-child {
  align-self: stretch; }

// ---------- ---------- ---------- ---------- ---------- ---------- ---------- ---------- order
.od-sp0-lg1 {
  order: 0;
  @include media(lg) {
    order: 1;
  }
}
.od-sp1-lg0 {
  order: 1;
  @include media(lg) {
    order: 0;
  }
}

@for $i from 0 through 11 {
	.order-#{$i} {
		order: #{$i};
	}
}

.flex-row{
  display: flex;
  flex-direction: row;
}

.flex-column{
  display: flex;
  flex-direction: column;
}