@charset "UTF-8";

.hero {
  background: $second-color;
  color: inherit;
  margin-bottom: 3.2em;
  padding: 4em 0;
  position: relative;
  width: 100%;

  & .main-title {
    color: #fff;
    font-size: 3em;
    font-weight: bold;
    line-height: 1.2;
  }
}
