@charset "UTF-8";

// SUB PAGE
.map{
  height: 400px;
  border: solid 1px #ccc;
  @include media(md) {
    height: 600px;
  }
}

.img-content{
  width: 100%;
  display: block;
}
.img-text{
  padding: 1em;
  &.bg-cream{
    background: #f7f3ed;
  }
}

.msg{
  font-weight: bold;
  font-size: 1.25em;
  text-align: center;
  line-height: 1.5;
  span{
    padding-top: 0.5em;
    display: block;
    font-size: 1.5em;
    color: $color-red;
  }
}

.faq{
  li{
    padding: 1em 0;
    & + li{
      border-top: dotted 1px #ccc;
    }
    .question{
      color: $second-color;
      font-weight: bold;
      position: relative;
      padding-left: 1.75em;
      line-height: 1;
      &::before{
        content: 'Q';
        font-size: 1.25em;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }
    .answer{
      position: relative;
      padding-left: 1.75em;
      margin-top: 0.5em;
      line-height: 1.5;
      &::before{
        content: 'A';
        color: $first-color;
        font-weight: bold;
        font-size: 1.25em;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }
  }
}

.color-panel-wrapper{
  $border-width: 3px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @include media(md) {
    flex-direction: row;
  }
  .color-panel-box{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @include media(md) {
      flex-direction: row;
      align-items: unset;
    }
    & + .color-panel-box{
      margin-top: 1em;
      @include media(md) {
        margin-top: 0;
        margin-left: 1em;
      }
    }
    .color-panel-items{
      background: #fff;
      padding: 1em;
      border-left-style: solid;
      border-right-style: solid;
      border-width: $border-width;
      border-color: #707070;
      width: 80%;
      height: 100%;
      @include media(md) {
        border-left-style: none;
        border-right-style: none;
        border-top-style: solid;
        border-bottom-style: solid;
        width: 220px;
      }
      &:first-child{
        border-top-style: solid;
        border-top-left-radius: 0.5em;
        border-top-right-radius: 0.5em;
        border-width: $border-width;
        border-color: #707070;
        @include media(md) {
          border-top-right-radius: 0;
          border-top-left-radius: 0.5em !important;
          border-bottom-left-radius: 0.5em !important;
          border-left-style: solid;
        }
      }
      &:last-child{
        border-width: $border-width;
        border-color: #707070;
        border-bottom-style: solid;
        border-bottom-left-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
        @include media(md) {
          border-right-style: solid;
          border-bottom-left-radius: 0;
          border-top-right-radius: 0.5em !important;
          border-bottom-right-radius: 0.5em !important;
        }
      }
      & + .color-panel-items{
        border-top-style: dotted;
        border-width: $border-width;
        border-color: #707070;
        @include media(md) {
          border-top-style: solid;
          border-left-style: dotted;
        }
      }
      .color-panel-title{
        background: #707070;
        color: #fff;
        padding: 0.125em 0.5em;
        text-align: center;
        margin-bottom: 1em;
        font-weight: bold;
      }
      .color-panel-img{
        width: 60%;
        margin: 0 auto;
        display: block;
        @include media(md) {
          width: 100%;
        }
      }
    }

    &.pink{
      .color-panel-items{
        $color: $third-color;
        border-color: $color;
        &:first-child{
          border-color: $color;
        }
        &:last-child{
          border-color: $color;
        }
        & + .color-panel-items{
          border-color: $color;
        }
        .color-panel-title{
          background: $color;
        }
      }
    }

    &.blue{
      .color-panel-items{
        $color: $base-color;
        border-color: $color;
        &:first-child{
          border-color: $color;
        }
        &:last-child{
          border-color: $color;
        }
        & + .color-panel-items{
          border-color: $color;
        }
        .color-panel-title{
          background: $color;
        }
      }
    }

    &.red{
      .color-panel-items{
        $color: $fourth-color;
        border-color: $color;
        &:first-child{
          border-color: $color;
        }
        &:last-child{
          border-color: $color;
        }
        & + .color-panel-items{
          border-color: $color;
        }
        .color-panel-title{
          background: $color;
        }
      }
    }

    &.green{
      .color-panel-items{
        $color: $second-color;
        border-color: $color;
        &:first-child{
          border-color: $color;
        }
        &:last-child{
          border-color: $color;
        }
        & + .color-panel-items{
          border-color: $color;
        }
        .color-panel-title{
          background: $color;
        }
      }
    }

    &.orange{
      .color-panel-items{
        $color: $first-color;
        border-color: $color;
        &:first-child{
          border-color: $color;
        }
        &:last-child{
          border-color: $color;
        }
        & + .color-panel-items{
          border-color: $color;
        }
        .color-panel-title{
          background: $color;
        }
      }
    }

  }
}

.video-list-wrapper{
  .video-list-item{
    display: flex;
    padding: 1em;
    
    transition: background 0.2s;
    flex-direction: column;
    @include media(md) {
      flex-direction: row;
      align-items: center;
    }
    & + .video-list-item{
      border-top: dashed 1px #ccc;
    }
    &:hover{
      background: rgba($base-color, 0.125);
    }
  }
  .video-list-desc{

  }
  .video-list-img{
    width: 100%;
    margin-bottom: 1em;
    @include media(md) {
      width: 28%;
      margin-right: 2em;
      margin-bottom: 0;
    }
  }
  .video-list-heading{
    display: block;
    color: #333;
    font-size: 1.25em;
    font-weight: bold;
    position: relative;
  }
  .video-list-time{
    display: block;
    color: $gray;
    font-weight: bold;
    font-size: 1em;
    position: relative;
    padding-left: 1.25em;
    &::before{
      content: '\f017';
      font-family: FontAwesome;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .video-list-text{
    color: #333;
    margin-top: 0.5em;
    display: block;
  }
}

.link-btn-list{
  margin: -0.25em;
  display: flex;
  justify-content: space-between;
  //flex-direction: column; 
  flex-wrap: wrap;
  @include media(md) {
    //flex-direction: row;
    flex-wrap: nowrap;
    margin: -0.125em;
  }
  .link-btn-item{
    width: 50%;
    padding: 0.25em;
    @include media(md) {
      padding: 0.125em;
      width: 100%;
    }
    .link-btn-elm{
      text-align: center;
      display: block;
      background: $gray-lightest;
      color: #333;
      line-height: 1.2;
      padding: 0.75em;
      border-radius: 4px;
      font-weight: bold;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: solid 1px $gray-light;
      transition: background 0.2s;
      @include media(md) {
        padding: 0.5em;
        &:hover:not(.active){
          background: #ccc;
        }
      }
      &.active{
        background: #ccc;
      }
      &.pink{
        background: $third-color;
        border: solid 1px $third-color;
        color: #fff;
        &:hover{
          background: $third-color;
          border: solid 1px $third-color;
          color: #fff;
        }
      }
    }
  }
}

.circle-wrapper{
  position: relative;
  padding: 0 7rem;
  display: inline-block;
  @include media(md) {
    padding: 0 9rem;
  }
  .circle-item{
    text-align: center;
    background: $fourth-color;
    color: #fff;
    width: 6rem;
    height: 6rem;
    line-height: 1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 90%;
    @include media(md) {
      width: 8rem;
      height: 8rem;
      font-size: 1em;
    }
  }
}

.bg-box{
  background: #fff;
  padding: 1em;
  @include media(md) {
    padding: 1.5em;
  }
}

.melit-wrapper {
  border: solid 3px #F8B64A;
  background: #fef5e7;
  font-weight: bold;
  font-size: 1.125em;
  position: relative;
  padding: 2em 1.5em 1.5em 1.5em;
  text-align: center;
  height: 100%;
  border-radius: 50%;
  color: #333;
  &::before {
    content: '';
    background-image: url("../../images/car/melit.svg");
    width: 3em;
    height: 3em;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    left: 50%;
    top: -1.5em;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    display: inline-block;
  }
}

.emphasis-wrapper {
  background: #e5f1fe;
  padding: 1.5em;
  color: #333;
  .emphasis-heading {
    color: #e9546b;
    font-weight: bold;
    font-size: 1.25em;
    line-height: 1.5;
  }
  .emphasis-heading br {
    display: none;
    @include media(md) {
      display: block;
    }    
  }
  .emphasis-sub-heading {
    color: #e9546b;
    font-weight: bold;
    font-size: 1.125em;
    line-height: 1.5;
    background: #fef5e7;
    padding: 0.5em 1em;
    border-bottom: solid 2px #e9546b;
  }
  .emphasis-inner-box {
    background: #fff;
    padding: 1.5em;
  }
}

.link-bnr-btn{
  background: #fff;
  border-radius: 1em;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1em 1.5em;
  border: solid 3px $gray-lighter;
  align-items: center;
  transition: border 0.2s;
  .link-bnr-txt{
    color: $base-color;
    font-weight: bold;
    display: block;
    line-height: 1.3;
    br{
      @include media(md) {
        display: none;
      }
    }
  }
  .link-bnr-img{
    width: 150px;
  }
  @media (hover: hover) {
    &:hover {
      border: solid 3px $base-color;
    }
  }
}

.flow-wrapper{
  .flow-items{
    border: solid 2px $base-color;
    padding: 1em;
    background: #fff;
    position: relative;
    .flow-heading{
      font-weight: bold;
      font-size: 1.25em;
      & + .flow-body{
        margin-top: 0.5em;
      }
    }
    & + .flow-items{
      margin-top: 3em;
      &::before{
        font-family: FontAwesome;
        content: '\f078';
        color: $base-color;
        position: absolute;
        font-size: 1.75em;
        top: -1.5em;
        left: 50%;
        transform: translateX(-50%);
        display: inline-block;
        font-weight: 900;
        line-height: 1;
      }
    }
  }
}

$contact-color:#00A73D;
.contact-wrapper{
  display: flex;
  flex-wrap: wrap;
  margin: -.5em;
}
.contact-column{
  width: 100%;
  padding: .5em;
  @include media(md) {
    width: 50%;
  }
}
.contact-panel{
  background-color:#F8F5E9;
  padding: 1em 2em;
  height: 100%;
}
.contact-heading{
  text-align: center;
  font-weight: bold;
  font-size: 1.25em;
  margin-bottom: .5em;
}
.contact-icon{
  width: 16rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.tel-heading{
  text-align: center;
  font-weight: bold;
  color: $contact-color;
  font-size: 0.9375em;
}
.tel-desc{
  font-size: 1.75em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  &::before{
    content: '';
    background-image: url('/images/contact/freedial.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 2em;
    height: 2em;
    display: block;
    margin-right: 0.25em;
  }
}
.tel-number{
  color: #333;
  font-weight: bold;
  line-height: 1.5;
  &:hover{
    color: #333;
  }
}
.tel-caption{
  margin-top: 0;
  text-align: center;
  font-size: 0.9375em;
}
.contact-btn{
  background: $contact-color;
  color: #fff;
  text-align: center;
  font-weight: bold;
  padding: .5em 3em;
  border-radius: 5px;
  border-bottom: solid 4px darken($contact-color, 5%);
  position: relative;
  &::after{
    content: '';
    background-image: url('/images/contact/external.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 1em;
    height: 1em;
    display: block;
    right: 1em;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
  }
  // & + .contact-btn{
  //   margin-top: 1em;
  // }
  &.expanded{
    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  &:hover{
    color: #fff;
  }
}
.contact-desc{
  text-align: center;
}

.bnr-link{
  border-radius: 0.5em;
  display: block;
  overflow: hidden;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  border: solid 1px #ccc;
  transition: opacity 0.2s;
  &:hover{
    opacity: 0.6;
  }
}