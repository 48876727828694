@charset "UTF-8";

// grid
.grid {
  display: flex;
  flex-flow: row wrap;
  margin: (- container-padding-grid(sm)) (- container-padding-grid(sm)) 0;
  @include media(md) {
    max-width: width(md);
    margin: (- container-padding-grid(md)) (- container-padding-grid(md)) 0;
  }
  @include media(lg) {
    max-width: width(lg);
    margin: (- container-padding-grid(lg)) (- container-padding-grid(lg)) 0;
  }
  & +.grid {
    margin: 0 (- container-padding-grid(sm)) 0;
    @include media(md) {
      max-width: width(md);
      margin: 0 (- container-padding-grid(md)) 0;
    }
    @include media(lg) {
      max-width: width(lg);
      margin: 0 (- container-padding-grid(lg)) 0;
    }
  }

  & .grid {
    margin: (- container-padding-grid(sm));
    &.gapless {
      margin: 0;
    }
    @include media(md) {
      margin: 0 (- container-padding-grid(md));
    }
    @include media(lg) {
      margin: 0 (- container-padding-grid(lg));
    }
  }

  &.gapless {
    margin: container-padding-grid(sm) 0 0;
    & + .gapless {
      margin: 1rem 0 ;
      @include media(lg) {
        margin: 2rem 0;
      }
    }
    & .grid {

      & .grid {
        margin: (- container-padding-grid(sm));
        @include media(lg) {
          margin: (container-padding-grid(lg) / 2) (- container-padding-grid(lg));
        }
      }
    }
    @include media(lg) {
      margin: container-padding-grid(lg) 0;
    }
  }
}

// col
.col {
  padding: container-padding-grid(sm);
  flex: 1 1 0;
  @include media(md) {
    padding: container-padding-grid(md);
    width: auto;
  }
  @include media(lg) {
    padding: container-padding-grid(lg);
  }

  & figure {
    text-align: center;
  }

  & > .col {
    padding: container-padding-grid(sm) 0;
    margin: (- container-padding-grid(sm)) 0 container-padding-grid(sm);
    @include media(md) {
      padding: container-padding-grid(md) 0;
      margin: (- container-padding-grid(md)) 0 container-padding-grid(md);
    }
    @include media(lg) {
      padding: container-padding-grid(lg) 0;
      margin: (- container-padding-grid(lg)) 0 container-padding-grid(lg);
    }
  }

  & > .grid {
    margin: (- container-padding-grid(sm));
    & + .grid {
      margin: container-padding-grid(sm) (- container-padding-grid(sm));
      .gapless & {
        margin: 0;
      }
    }
    @include media(md) {
      margin: (- container-padding-grid(md));
      & + .grid {
        margin: container-padding-grid(md) (- container-padding-grid(md));
      }
    }
    @include media(lg) {
      margin: (- container-padding-grid(lg));
      & + .grid {
        margin: container-padding-grid(lg) (- container-padding-grid(lg));
      }
    }
  }

  & > .grid.gapless {
    margin: container-padding-grid(sm) 0;

    & + .grid.gapless {
      margin: (- container-padding-grid(sm)) 0 0;
    }

    @include media(lg) {
      margin: container-padding-grid(lg) 0;

      & + .grid.gapless {
        margin: (- container-padding-grid(lg)) 0 0;
      }
    }
  }
}

.raw-col {
  text-align: center;
  padding: container-padding-grid(sm);
  @include media(md) {
    padding: container-padding-grid(md);
    width: auto;
  }
  @include media(lg) {
    padding: container-padding-grid(lg);
  }
  & figure {
    text-align: center;
  }
}


// ++++++++++++++++++++++++++++++ Smart Phone 767px以下*/
@for $i from 1 through $grid-columns {
  .sm-#{$i} {
    @include make-grid(100%/($grid-columns / $i));
  }
  .sm-#{$i}-center {
    @include make-grid(100%/($grid-columns / $i));
    margin: 0 auto;
  }
  .sm-offset-#{$i} {
    margin-left:(100%/($grid-columns / $i));
  }
}

@include media(md) {
  @for $i from 1 through $grid-columns {
    .md-#{$i} {
      @include make-grid(100%/($grid-columns / $i));
    }
    .md-#{$i}-center {
      @include make-grid(100%/($grid-columns / $i));
      margin: 0 auto;
    }
    .md-offset-#{$i} {
      margin-left:(100%/($grid-columns / $i));
    }
  }
}

@include media(lg) {
  @for $i from 1 through $grid-columns {
    .lg-#{$i} {
      @include make-grid(100%/($grid-columns / $i));
    }
    .lg-#{$i}-center {
      @include make-grid(100%/($grid-columns / $i));
      margin: 0 auto;
    }
    .lg-offset-#{$i} {
      margin-left:(100%/($grid-columns / $i));
    }
  }
}

// ++++++++++++++++++++++++++++++ 12.col */
  @for $i from 1 through $grid-columns {
    .col-#{$i} {
      @include make-grid(100%/($grid-columns / $i));
    }
  }
